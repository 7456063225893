<template>
  <div>
    <facturacion-y-pendiente-cobro
      :estado-filtro="'FACTURADO'"
      :show-cobro="true"
      :show-facturacion="false"
    />
  </div>
</template>

<script>
import FacturacionYPendienteCobro from '@/components/crecimientoRed/tablaContenidoAmpliaciones.vue'

export default {
  components: {
    FacturacionYPendienteCobro,
  },
  data() {
    return {
      estadoFiltro: 'COBRAR',
    }
  },
}
</script>

<style>

</style>
