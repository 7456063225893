<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-detalle
      v-b-modal.modal-facturacion
      variant="relief-primary"
      block
    >
      Reporte Facturación
    </b-button>

    <!-- select 2 demo -->
    <b-modal
      id="modal-facturacion"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      title="Generar Reporte de Facturación"
      ok-title="Generar"
      cancel-variant="outline-secondary"
      :click-to-close="false"
      @ok="generarExcel"
      @show="resetModal"
      @hidden="resetModal"
    >
      <b-row>
        <b-col cols="12">
          <label>Seleccione la fecha del reporte a generar</label>
        </b-col>
        <b-col cols="6">
          <label>Mes: </label>
          <v-select
            id="vue-select"
            v-model="mes"
            :options="meses"
            label="nombre"
          />
        </b-col>
        <b-col cols="6">
          <label>Año: </label>
          <v-select
            id="vue-select"
            v-model="año"
            :options="años"
            label="nombre"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>

</template>

<script>
import {
  BModal, BButton, VBModal, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { findAmpliaciones } from '@/utils/inspecciones'
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from 'sweetalert2'
import xl from 'excel4node'
import { getDatosInformeAmpliacion, getlistado } from '@/utils/catalogos'
import { ordernarItemsAmpliacion } from '@/utils/files'
import { getListadoMeses } from '@/utils/fechas'

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      usuario: JSON.parse(localStorage.getItem('userData')),
      fechaFin: '',
      fechaInicio: '',
      mes: null,
      año: null,
      años: [],
      meses: [],
    }
  },
  created() {
    this.años = getListadoMeses(2)
    this.meses = getListadoMeses(1)
    this.obtenerDatosCatalogos()
    // eslint-disable-next-line no-unused-vars
      .catch(err => {
        console.error('Error al obtener los catálogos', err)
      })
  },
  methods: {
    async obtenerDatosCatalogos() {
      try {
        const tipoPostes = await getlistado(1, 'Tipos de Postes', true, this.usuario)
        const tipoLampara = await getlistado(9, 'Clase Lámparas', true, this.usuario)
        const unidadesVarias = await getlistado(10, 'Unidades Varias', true, this.usuario)
        const tiposBrazo = await getlistado(14, 'Tipos Brazo', true, this.usuario)

        return {
          tiposBrazo,
          tipoPostes,
          tipoLampara,
          unidadesVarias,
        }
      } catch (err) {
        console.error('Error al obtener los catálogos', err)
        return {}
      }
    },
    recalcularTotal(item) {
      let total = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const row of item.items) {
        total += Math.round(row.item.precio.precioVenta)
      }
      return total
    },
    resetModal() {
      this.mes = null
      this.año = null
      this.fechaFin = ''
      this.fechaInicio = ''
    },
    async generarExcel() {
      if (this.mes === null || this.año === null) {
        this.mensajeError('Campos Requeridos!', 'Mes y Año requeridos para generar el reporte!')
        return
      }
      const mesValor = this.mes.id
      const anoValor = this.año.id

      this.fechaFin = `${this.año.id}-${this.mes.id}-${this.mes.dia}`
      this.fechaInicio = `${this.año.id}-${this.mes.id}-01`
      this.getData().then(async data => {
        if (data.length === 0) {
          this.mensajeError('Ampliaciones', 'No se encontraron ampliaciones en la fecha seleccionada!')
          return
        }

        // eslint-disable-next-line global-require,no-shadow
        const xl = require('excel4node')
        // Create a new instance of a Workbook class
        const wb = new xl.Workbook()
        // Add Worksheets to the workbook
        const ws = wb.addWorksheet('Hoja 1')

        const tipoPostes = await getlistado(1, 'Tipos de Postes', true, this.usuario)
        const tipoLampara = await getlistado(9, 'Clase Lámparas', true, this.usuario)
        const unidadesVarias = await getlistado(10, 'Unidades Varias', true, this.usuario)
        const tiposBrazo = await getlistado(14, 'Tipos Brazo', true, this.usuario)

        const styleItems = wb.createStyle({
          font: {
            bold: true,
            size: 12,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        })
        const styleCeldaSuma = wb.createStyle({
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: '#FFFFFF' },
            left: { style: 'thin', color: '#FFFFFF' },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: '#FFFFFF' },
          },
          numberFormat: 'Q #,##0.00',
        })
        const styleCeldas = wb.createStyle({
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: '#FFFFFF' },
            left: { style: 'thin', color: '#FFFFFF' },
            bottom: { style: 'thin', color: '#FFFFFF' },
            right: { style: 'thin', color: '#FFFFFF' },
          },
        })
        const styleCantidades = wb.createStyle({
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: '#FFFFFF' },
            left: { style: 'thin', color: '#FFFFFF' },
            bottom: { style: 'thin', color: '#FFFFFF' },
            right: { style: 'thin', color: '#FFFFFF' },
          },
          numberFormat: 'Q #,##0.00',
        })
        const styleCeldasBorde = wb.createStyle({
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: '#869499' },
            left: { style: 'thin', color: '#869499' },
            bottom: { style: 'thin', color: '#869499' },
            right: { style: 'thin', color: '#869499' },
          },
        })

        const styleCeldasColorCeleste = wb.createStyle({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#daeef3',
          },
        })

        const styleCeldasColorAzul = wb.createStyle({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#b7dee8',
          },
        })

        // Set value of cell A1 to 100 as a number type styled with paramaters of style
        ws.cell(1, 1)
          .string(`ORDEN DE AMPLIACIÓN ${mesValor}-${anoValor}`)

        ws.cell(3, 2, 3, 6, true)
          .string(`AMPLIACIÓN ${Number(mesValor)}-${anoValor}`).style({
            font: {
              bold: true,
              size: 14,
            },
            alignment: {
              horizontal: 'center',
            },
          })

        ws.cell(4, 2, 5, 6, true)
          .string('CONCESIÓN DE LOS SERVICIOS PÚBLICOS DE ADMINISTRACIÓN, CONSERVACIÓN Y MEJORAMIENTO DEL ALUMBRADO PÚBLICO DEL MUNICIPIO DE VILLA NUEVA, DEPARTAMENTO DE GUATEMALA').style({
            font: {
              size: 8,
            },
            alignment: {
              horizontal: 'center',
              vertical: 'distributed',
              wrapText: true,
            },
          })

        ws.cell(6, 2, 6, 6, true)
          .string('NOG: 2854449').style({
            font: {
              bold: true,
              size: 10,
            },
            alignment: {
              horizontal: 'center',
              wrapText: true,
            },
          })

        ws.cell(8, 2).string('ITEM').style(styleItems)
        ws.cell(8, 3).string('Unidad Constructiva').style(styleItems)
        ws.cell(8, 4).string('Precio Unidad').style(styleItems)
        ws.cell(8, 5).string('Cantidad Total 2023').style(styleItems)
        ws.cell(8, 6).string('TOTAL COSTO').style(styleItems)

        for (let i = 9; i <= 28; i++) {
          if (i % 2 === 0) {
            ws.cell(i, 3).style(styleCeldasColorCeleste)
            ws.cell(i, 4).style(styleCeldasColorCeleste)
            ws.cell(i, 5).style(styleCeldasColorCeleste)
            ws.cell(i, 6).style(styleCeldasColorCeleste)
          } else {
            ws.cell(i, 3).style(styleCeldasColorAzul)
            ws.cell(i, 4).style(styleCeldasColorAzul)
            ws.cell(i, 5).style(styleCeldasColorAzul)
            ws.cell(i, 6).style(styleCeldasColorAzul)
          }
        }

        const totales = {
          item1: 0,
          item2: 0,
          item3: 0,
          item4: 0,
          item5: 0,
          item6: 0,
          item7: 0,
          item8: 0,
          item9: 0,
          item10: 0,
          item11: 0,
          item12: 0,
          item13: 0,
          item14: 0,
          item15: 0,
          item16: 0,
          item17: 0,
          item18: 0,
          item19: 0,
          item20: 0,
          item21: 0,
          item22: 0,
          ampliaciones: [],
        }

        const listado = await getDatosInformeAmpliacion()

        const precioLamp1 = tipoLampara[0].precio.precioVenta
        const precioLamp2 = tipoLampara[1].precio.precioVenta
        const precioLamp3 = tipoLampara[2].precio.precioVenta
        const precioLamp4 = tipoLampara[3].precio.precioVenta
        const precioLamp5 = tipoLampara[4].precio.precioVenta
        const precioLamp6 = tipoLampara[5].precio.precioVenta
        const precioPost1 = tipoPostes[0].precio.precioVenta
        const precioPost2 = tipoPostes[1].precio.precioVenta
        const precioPost3 = tipoPostes[2].precio.precioVenta
        const precioPost4 = tipoPostes[3].precio.precioVenta
        const precioPost5 = tipoPostes[4].precio.precioVenta
        const precioVar1 = unidadesVarias[0].precio.precioVenta
        const precioBra1 = tiposBrazo[0].precio.precioVenta
        const precioBra2 = tiposBrazo[1].precio.precioVenta
        const precioBra3 = tiposBrazo[2].precio.precioVenta
        const precioBra4 = tiposBrazo[3].precio.precioVenta
        const precioVar2 = unidadesVarias[1].precio.precioVenta
        const precioVar3 = unidadesVarias[2].precio.precioVenta
        const precioVar4 = unidadesVarias[3].precio.precioVenta
        const precioVar5 = unidadesVarias[4].precio.precioVenta

        // eslint-disable-next-line no-unused-vars
        const sumaTotal = 0
        // eslint-disable-next-line no-restricted-syntax
        for await (const ampliacion of data) {
          const items = await ordernarItemsAmpliacion({ ...ampliacion }, listado)
          totales.item1 += items.filter(i => i.correlativo === 1).length
          totales.item2 += items.filter(i => i.correlativo === 2).length
          totales.item3 += items.filter(i => i.correlativo === 3).length
          totales.item4 += items.filter(i => i.correlativo === 4).length
          totales.item5 += items.filter(i => i.correlativo === 5).length
          totales.item6 += items.filter(i => i.correlativo === 6).length
          totales.item7 += items.filter(i => i.correlativo === 7).length
          totales.item8 += items.filter(i => i.correlativo === 8).length
          totales.item9 += items.filter(i => i.correlativo === 9).length
          totales.item10 += items.filter(i => i.correlativo === 10).length
          totales.item11 += items.filter(i => i.correlativo === 11).length
          totales.item12 += ampliacion.metros
          totales.item13 += items.filter(i => i.correlativo === 13).length
          totales.item14 += items.filter(i => i.correlativo === 14).length
          totales.item15 += items.filter(i => i.correlativo === 15).length
          totales.item16 += items.filter(i => i.correlativo === 16).length
          totales.item17 += items.filter(i => i.correlativo === 17).length
          totales.item18 += items.filter(i => i.correlativo === 18).length
          totales.item19 += items.filter(i => i.correlativo === 19).length
          totales.item20 += items.filter(i => i.correlativo === 20).length
          totales.item21 += items.filter(i => i.correlativo === 21).length
          totales.ampliaciones.push({
            id: ampliacion.id,
            items,
          })

          const Lamp1 = precioLamp1 * totales.item1
          const Lamp2 = precioLamp2 * totales.item2
          const Lamp3 = precioLamp3 * totales.item3
          const Lamp4 = precioLamp4 * totales.item4
          const Lamp5 = precioLamp5 * totales.item5
          const Lamp6 = precioLamp6 * totales.item6

          const Post1 = precioPost1 * totales.item7
          const Post2 = precioPost2 * totales.item8
          const Post3 = precioPost3 * totales.item9
          const Post4 = precioPost4 * totales.item10
          const Post5 = precioPost5 * totales.item11

          const Var1 = precioVar1 * totales.item12

          const Bra1 = precioBra1 * totales.item13
          const Bra2 = precioBra2 * totales.item14
          const Bra3 = precioBra3 * totales.item15
          const Bra4 = precioBra4 * totales.item16

          const Var2 = precioVar2 * totales.item17
          const Var3 = precioVar3 * totales.item18
          const Var4 = precioVar4 * totales.item19
          const Var5 = precioVar5 * totales.item20

          const totalLamparas = Lamp1 + Lamp2 + Lamp3 + Lamp4 + Lamp5 + Lamp6
          const totalPostes = Post1 + Post2 + Post3 + Post4 + Post5
          const totalVarias = Var1 + Var2 + Var3 + Var4 + Var5
          const totalBrazos = Bra1 + Bra2 + Bra3 + Bra4

          // eslint-disable-next-line no-shadow
          const sumaTotal = totalLamparas + totalPostes + totalVarias + totalBrazos

          ws.cell(9, 6).number(Lamp1).style(styleCantidades)
          ws.cell(10, 6).number(Lamp2).style(styleCantidades)
          ws.cell(11, 6).number(Lamp3).style(styleCantidades)
          ws.cell(12, 6).number(Lamp4).style(styleCantidades)
          ws.cell(13, 6).number(Lamp5).style(styleCantidades)
          ws.cell(14, 6).number(Lamp6).style(styleCantidades)

          ws.cell(15, 6).number(Post1).style(styleCantidades)
          ws.cell(16, 6).number(Post2).style(styleCantidades)
          ws.cell(17, 6).number(Post3).style(styleCantidades)
          ws.cell(18, 6).number(Post4).style(styleCantidades)
          ws.cell(19, 6).number(Post5).style(styleCantidades)

          ws.cell(20, 6).number(Var1).style(styleCantidades)

          ws.cell(21, 6).number(Bra1).style(styleCantidades)
          ws.cell(22, 6).number(Bra2).style(styleCantidades)
          ws.cell(23, 6).number(Bra3).style(styleCantidades)
          ws.cell(24, 6).number(Bra4).style(styleCantidades)

          ws.cell(25, 6).number(Var2).style(styleCantidades)
          ws.cell(26, 6).number(Var3).style(styleCantidades)
          ws.cell(27, 6).number(Var4).style(styleCantidades)
          ws.cell(28, 6).number(Var5).style(styleCantidades)

          ws.cell(29, 6).number(sumaTotal).style(styleCeldaSuma)
        }

        for (let i = 9; i <= 28; i++) {
          ws.cell(i, 2).number(i - 8).style(styleCeldasBorde)
        }

        ws.cell(9, 3).string('Lámpara A').style(styleCeldas)
        ws.cell(10, 3).string('Lámpara B').style(styleCeldas)
        ws.cell(11, 3).string('Lámpara C').style(styleCeldas)
        ws.cell(12, 3).string('Lámpara E').style(styleCeldas)
        ws.cell(13, 3).string('Lámpara F').style(styleCeldas)
        ws.cell(14, 3).string('Lámpara G').style(styleCeldas)
        ws.cell(15, 3).string("Poste Concreto 30'").style(styleCeldas)
        ws.cell(16, 3).string("Poste Concreto 35'").style(styleCeldas)
        ws.cell(17, 3).string("Poste Concreto 40'").style(styleCeldas)
        ws.cell(18, 3).string("Poste Metal 25' Seccionado").style(styleCeldas)
        ws.cell(19, 3).string("Poste Metal 35'").style(styleCeldas)
        ws.cell(20, 3).string('Metro Línea 240V').style(styleCeldas)
        ws.cell(21, 3).string("Brazo 1 (4')").style(styleCeldas)
        ws.cell(22, 3).string("Brazo 2 (6')").style(styleCeldas)
        ws.cell(23, 3).string("Brazo 3 (8')").style(styleCeldas)
        ws.cell(24, 3).string("Brazo 4 (12')").style(styleCeldas)
        ws.cell(25, 3).string('Bajada a Tierra Física').style(styleCeldas)
        ws.cell(26, 3).string('Montaje y Configuración').style(styleCeldas)
        ws.cell(27, 3).string('Solo Mano de Obra Instalación Poste').style(styleCeldas)
        ws.cell(28, 3).string('Servicio de Movimiento de Poste').style(styleCeldas)

        ws.cell(9, 4).number(tipoLampara[0].precio.precioVenta).style(styleCantidades)
        ws.cell(10, 4).number(tipoLampara[1].precio.precioVenta).style(styleCantidades)
        ws.cell(11, 4).number(tipoLampara[2].precio.precioVenta).style(styleCantidades)
        ws.cell(12, 4).number(tipoLampara[3].precio.precioVenta).style(styleCantidades)
        ws.cell(13, 4).number(tipoLampara[4].precio.precioVenta).style(styleCantidades)
        ws.cell(14, 4).number(tipoLampara[5].precio.precioVenta).style(styleCantidades)
        ws.cell(15, 4).number(tipoPostes[0].precio.precioVenta).style(styleCantidades)
        ws.cell(16, 4).number(tipoPostes[1].precio.precioVenta).style(styleCantidades)
        ws.cell(17, 4).number(tipoPostes[2].precio.precioVenta).style(styleCantidades)
        ws.cell(18, 4).number(tipoPostes[3].precio.precioVenta).style(styleCantidades)
        ws.cell(19, 4).number(tipoPostes[4].precio.precioVenta).style(styleCantidades)
        ws.cell(20, 4).number(unidadesVarias[0].precio.precioVenta).style(styleCantidades)
        ws.cell(21, 4).number(tiposBrazo[0].precio.precioVenta).style(styleCantidades)
        ws.cell(22, 4).number(tiposBrazo[1].precio.precioVenta).style(styleCantidades)
        ws.cell(23, 4).number(tiposBrazo[2].precio.precioVenta).style(styleCantidades)
        ws.cell(24, 4).number(tiposBrazo[3].precio.precioVenta).style(styleCantidades)
        ws.cell(25, 4).number(unidadesVarias[1].precio.precioVenta).style(styleCantidades)
        ws.cell(26, 4).number(unidadesVarias[2].precio.precioVenta).style(styleCantidades)
        ws.cell(27, 4).number(unidadesVarias[3].precio.precioVenta).style(styleCantidades)
        ws.cell(28, 4).number(unidadesVarias[4].precio.precioVenta).style(styleCantidades)

        ws.cell(9, 5).number(totales.item1).style(styleCeldas)
        ws.cell(10, 5).number(totales.item2).style(styleCeldas)
        ws.cell(11, 5).number(totales.item3).style(styleCeldas)
        ws.cell(12, 5).number(totales.item4).style(styleCeldas)
        ws.cell(13, 5).number(totales.item5).style(styleCeldas)
        ws.cell(14, 5).number(totales.item6).style(styleCeldas)
        ws.cell(15, 5).number(totales.item7).style(styleCeldas)
        ws.cell(16, 5).number(totales.item8).style(styleCeldas)
        ws.cell(17, 5).number(totales.item9).style(styleCeldas)
        ws.cell(18, 5).number(totales.item10).style(styleCeldas)
        ws.cell(19, 5).number(totales.item11).style(styleCeldas)
        ws.cell(20, 5).number(totales.item12).style(styleCeldas)
        ws.cell(21, 5).number(totales.item13).style(styleCeldas)
        ws.cell(22, 5).number(totales.item14).style(styleCeldas)
        ws.cell(23, 5).number(totales.item15).style(styleCeldas)
        ws.cell(24, 5).number(totales.item16).style(styleCeldas)
        ws.cell(25, 5).number(totales.item17).style(styleCeldas)
        ws.cell(26, 5).number(totales.item18).style(styleCeldas)
        ws.cell(27, 5).number(totales.item19).style(styleCeldas)
        ws.cell(28, 5).number(totales.item20).style(styleCeldas)

        ws.column(1).setWidth(5)
        ws.column(2).setWidth(5)
        ws.column(3).setWidth(30)
        ws.column(4).setWidth(20)
        ws.column(5).setWidth(20)
        ws.column(6).setWidth(30)

        // ws.row(3).setHeight(140)

        for (let i = 8; i <= 2; i++) {
          ws.row(i).setHeight(25)
        }

        const buffer = await wb.writeToBuffer()
        const string = buffer.toString('base64')
        const linkSource = `data:application/xlsx;base64,${string}`
        const downloadLink = document.createElement('a')
        const fileName = `FACTURADAS${mesValor}${anoValor}.xlsx`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      })
    },
    async getData() {
      const fechaInicio = new Date(this.fechaInicio)
      const fechaFin = new Date(this.fechaFin)

      const filter = {
        where: {
          and: [
            { fechaCreacion: { gte: fechaInicio.toISOString() } },
            { fechaCreacion: { lte: fechaFin.toISOString() } },
            { estado: 'FACTURADO' },
            { companyId: this.usuario.idCompany },
          ],
        },
        order: ['fechaCreacion DESC'],
      }

      return findAmpliaciones(filter)
    },
    mensajeError(titulo, mensaje) {
      Swal.fire({
        title: titulo,
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
