<template>
  <div>
    <b-row>
      <b-col cols="3">
        <label>Número de Poste</label>
        <b-form-input
          :value="ampliacion.poste"
          disabled
        />
      </b-col>
      <b-col cols="6">
        <label>Encargado asignado</label>
        <b-form-input
          :value="ampliacion.usuario.nombre"
          disabled
        />
      </b-col>
      <b-col cols="3">
        <label>Municipio</label>
        <b-form-input
          :value="ampliacion.municipio.nombre"
          disabled
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <label>Localidad / Población</label>
        <b-form-input
          :value="ampliacion.localidad.nombre"
          disabled
        />
      </b-col>
      <b-col cols="4">
        <label>Zona</label>
        <b-form-input
          :value="ampliacion.localidad.zona"
          disabled
        />
      </b-col>
      <b-col cols="3">
        <label>Calle / Avenida</label>
        <b-form-input
          :value="ampliacion.calleAvenida"
          disabled
        />
      </b-col>
      <b-col cols="3">
        <label>Numeral</label>
        <b-form-input
          :value="ampliacion.numeral"
          disabled
        />
      </b-col>
      <b-col cols="2">
        <label>Metros de Linea</label>
        <b-form-input
          :value="ampliacion.metros"
          disabled
        />
      </b-col>
      <b-col cols="4">
        <label>Es un punto nuevo?</label>
        <b-form-input
          :value="ampliacion.puntoExistente ? 'SI' : 'NO'"
          disabled
        />
      </b-col>
      <b-col
        v-if="ampliacion.fechaRealInstalacion && ampliacion.fechaRealInstalacion !== ''"
        cols="4"
      >
        <label>Fecha Real Instalación</label>
        <b-form-input
          :value="ampliacion.fechaRealInstalacion"
          disabled
        />
      </b-col>
      <b-col
        v-if="ampliacion.mesInformeAmpliacion && ampliacion.mesInformeAmpliacion !== ''"
        cols="4"
      >
        <label>Mes de Informe de Ampliación</label>
        <b-form-input
          :value="ampliacion.mesInformeAmpliacion.nombre"
          disabled
        />
      </b-col>
      <b-col
        v-if="ampliacion.latitud !== '' && ampliacion.longitud !==''"
        cols="12"
      >
        <label>Ubicación</label>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="gradient-primary"
          block
          @click="
            irRuta(ampliacion)
          "
        >
          <feather-icon icon="MapPinIcon" />
          <br>
          <br>
          Ver Ubicación en Google Maps
        </b-button>

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label>Comentarios</label>
        <b-form-textarea
          :value="ampliacion.comentarios"
          disabled
          no-resize
          rows="2"
        />
      </b-col>
    </b-row>
    <br>
    <b-row>
      <h5
        style="margin-left: 2%; color: #006394;"
      >Flujo Ampliación</h5>
      <b-col cols="12">
        <b-table
          responsive
          :items="ampliacion.tracking"
          :fields="camposTablaAmpliacion"
        >
          <template #cell(estado)="data">
            <span><b>{{ data.item.tipo }}</b></span>
          </template>
          <template #cell(usuario)="data">
            <span>{{ data.item.usuario? data.item.usuario.nombre : ampliacion.usuario.nombre }}</span>
          </template>
          <template #cell(comentario)="data">
            <span>{{ data.item.comentarios }}</span>
          </template>
          <!--BOTON-->
          <template #cell(imagen)="data">
            <b-button
              v-if="data.item.url"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              @click="mostrarImagen(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <h5
        style="margin-left: 2%; color: #006394;"
      >Accesorios</h5>
      <b-col cols="12">
        <b-table
          responsive
          :items="ampliacion.items"
          :fields="fields"
        >
          <template #cell(tipo)="data">
            <span>{{ data.item.tipo.nombre }}</span>
          </template>
          <template #cell(accesorio)="data">
            <span>{{ data.item.item.nombre }}</span>
          </template>
          <template #cell(seguimiento)="data">
            <b-button
              v-if="data.item.tracking && data.item.tracking.length > 0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              @click="mostrarDetalle(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>
      </b-col></b-row>
    <b-modal
      v-model="verDialogoDocumento"
      ok-only
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Detalle de la Imagen"
    >
      <div>
        <center>
          <b-img
            :src="urlImagen"
            fluid
            alt="Imagen Cargada')"
          />
        </center>
      </div>
    </b-modal>

    <b-modal
      v-model="verDialogItem"
      ok-only
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Seguimiento Accesorio"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <b-table
              responsive
              :items="item? item.tracking : []"
              :fields="camposTablaAmpliacion"
            >
              <template #cell(estado)="data">
                <span><b>{{ data.item.tipo }}</b></span>
              </template>
              <template #cell(usuario)="data">
                <span>{{ data.item.usuario? data.item.usuario.nombre : ampliacion.usuario.nombre }}</span>
              </template>
              <template #cell(comentario)="data">
                <span>{{ data.item.comentarios }}</span>
              </template>
              <!--BOTON-->
              <template #cell(imagen)="data">
                <b-button
                  v-if="data.item.url"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-primary"
                  @click="mostrarImagen(data.item)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol, BRow, BTable, BFormInput, BFormTextarea, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'DetalleAmpliacion',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BCol,
    BRow,
    BButton,
    BTable,
    BFormTextarea,
    BImg,
  },
  props: {
    ampliacion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: null,
      verDialogItem: false,
      verDialogoDocumento: false,
      urlImagen: '',
      camposTablaAmpliacion: ['estado', 'comentario', 'usuario', 'imagen'],
      fields: ['tipo', 'accesorio', 'seguimiento'],
      tableVariants: [
        'primary',
      ],
      striped: true,
      borderless: true,
      bordered: false,
      outlined: false,
      hover: false,
      dark: false,
      fixed: true,
      footClone: false,
      headVariant: null,
      noCollapse: false,
      tableVariant: 'primary',
    }
  },
  methods: {
    mostrarImagen(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
      this.verDialogoDocumento = true
    },
    mostrarDetalle(item) {
      this.item = item
      this.verDialogItem = true
    },
    irRuta(ampliacion) {
      if (ampliacion.latitud === '' || ampliacion.longitud === '') return
      const url = `https://maps.google.com/?q=${ampliacion.latitud},${ampliacion.longitud}`
      window.open(url, '_blank')
    },

  },
}
</script>

<style scoped>

</style>
